.time-activation-button.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 4px;
  box-sizing: border-box;


  * {
    box-sizing: border-box;
  }

  > * {
    margin: 0;
  }

  > [class^='icon'] {
    margin-right: 10px;

    [stroke] {
      stroke: var(--color-type-primary);
    }
  }

  .time-activation-button__value {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &:active,
  &.active {
    background-color: var(--color-accent);
    color: var(--color-element);
    border: 1.5px solid var(--color-accent);

    [stroke] {
      stroke: var(--color-element);
    }
  }
}
