@import '../../../../../../../../../styles/mixins.scss';

.energy-consumption-summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  margin-top: 24px;
  box-sizing: border-box;

  @include rwd(500) {
    flex-direction: column;
    margin-bottom: 24px;
  }

  &__element {
    display: flex;
    align-items: center;
    font-size: 16px;
    position: relative;
    line-height: 24px;
    font-weight: bold;

    @include rwd(500) {
      width: 100%;
      justify-content: space-between;
    }

    p {
      margin: 0;
    }
  }

  &__divider {
    width: 1px;
    height: 18px;
    background-color: var(--color-type-primary);
    opacity: 0.1;
  }

  &__text {
    color: var(--color-element-inactive-dark);
    margin-right: 8px;
    min-width: 50px;

    &--small {
      font-size: 14px;
    }
  }
}
