.notification-item-wrapper {
  position: relative;
  margin-bottom: 8px;
  border-radius: 16px;

  &.is-hidden {
    display: none;
  }

  &__delete-section {
    position: absolute;
    padding-left: 16px;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100px;
    border-radius: 16px;
    background: var(--color-red);
    z-index: 1;

    &.is-blocked {
      background: var(--color-element-inactive);
      pointer-events: none;
    }
  }
}

.notification-item {
  position: relative;
  width: 100%;
  height: 100%;
  transition-duration: 150ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2;

  &__header {
    border-bottom: 1px solid var(--color-background);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-icon {
      display: flex;
      align-items: center;
      height: 24px;
      width: 24px;
    }
  }

  &__time-elapse {
    color: var(--color-type-secondary);
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  :not(.notification-item--was-displayed).box {
    background-color: var(--color-element-inactive);
  }

  &--is-swiped {
    transform: translate3d(56px, 0, 0);
  }
}
