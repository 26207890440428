.blind-details__favourites {
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
  margin-top: 8px;

  * {
    box-sizing: border-box;
  }

  &.disable-padding {
    padding: 0;
  }

  .positions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
    margin-bottom: 8px;
  }
}