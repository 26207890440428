@import '../../../../../../styles/mixins.scss';

.condition-section {

  &--container {
    display: flex;
    justify-content: center;

    form {
      width: 100%;
    }
  }

  &__heading {
    margin-bottom: 24px;
    line-height: 24px;
  }

  &__label {
    font-weight: 400;
    line-height: 24px;
    margin: 0 0 8px;
  }

  &--badges {
    .condition-badges {
      display: flex;
      margin-bottom: 24px;
    }

    .condition-badge {
      display: flex;
      align-items: center;
      padding: 8px 16px;
      background: var(--color-element);
      border-radius: 8px;
      margin: 0 16px 0 0;

      span {
        margin-left: 8px;
      }
    }

    .condition-section {
      &__location {
        margin-bottom: 24px;
        display: flex;
        align-items: center;

        span {
          margin-right: 12px;
          margin-left: 8px;
          font-weight: 400;
          color: var(--color-type-primary);
        }
      }
    }
  }

  &--type {
    margin-top: 8px;
  }

  &--repeat {
    .condition-repeat {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      cursor: pointer;

      > div {
        display: flex;
      }

      p {
        font-size:16px;
        line-height: 24px;
        font-weight: 600;
      }

      &-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        border-radius: 12px;
        background: var(--color-element);
        cursor: pointer;

        &-container {
          display: flex;
          align-items: center;
          margin-bottom: 24px;
          width: 100%;

          .condition-repeat-box {
            width: 100%;
            margin-bottom: 0;

            @include rwd(500) {
              flex-direction: column;
              align-items: flex-start;
            }
          }

          .btn-delete {
            margin-left: 12px;
            height: 56px;
          }
        }

        input {
          cursor: pointer;
        }

        &--disabled {
          pointer-events: none;
          color: var(--color-element-inactive);
        }

        &--relative {
          position: relative;
        }

        &__heading {
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
        }

        &__date {
          font-size: 14px;
          line-height: 24px;
          font-weight: 400;
        }
      }

      &__next-execution {
        display: flex;

        p {
          margin: 0 0 0 8px;
          font-weight: 400;
          color: var(--color-type-secondary);
          line-height: 22px;
        }

        &--error {
          p {
            color: var(--color-red);
          }
        }
      }
    }
  }

  .condition-add {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    p {
      margin-left: 16px;
    }
  }
}
