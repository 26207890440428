.action-box--exalus {
  &.action-box {
    border: 1px solid var(--color-element);
    box-sizing: content-box;

    &--list-item {
      margin-bottom: 16px;
      min-height: 64px;
      box-sizing: border-box;

      .action-box__content {
        padding: 19px 16px;
      }

      .action-icon__icon {
        width: 22px;
        height: 22px;
      }
    }

    &:not(.action-box--list-item) {
      .action-icon__icon {
        width: 30px;
        height: 30px;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    &--hidden {
      display: none;
    }

    .action-box__head {
      &-action-name {
        margin: 0;
        font-weight: 600;
      }

      &--box {
        padding: 18px 16px 0;
        height: 74px;

        .action-icon {
          margin-right: 0;
        }
      }
    }
  }
}