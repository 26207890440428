.list-measurement {
  .measurement-item {
    height: auto;
    padding: 16px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    justify-content: flex-start;
    margin: 0 0 16px;
    background-color: var(--color-element);
    border-radius: 12px;

    &.clickable {
      cursor: pointer;
    }

    &::before {
      display: none;
    }

    &__unit-icon {
      width: 32px;
      height: 32px;
      line-height: 18px;
      font-weight: 500;
      color: var(--color-accent);
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__name {
      flex: 1;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      flex-direction: column;

      .subname {
        color: var(--color-type-secondary);
        font-size: 12px;
      }
    }

    &__value-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    &__value {
      display: flex;
      align-items: center;

      .icon-arrow-link {
        margin-right: 6px;
      }

      .icon-plug {
        width: 24px;
        height: 24px;
        
        [stroke] {
          stroke: var(--color-type-primary);
        }
      }
    }

    &__icon {
      cursor: pointer;
      height: 32px;
      width: 32px;
      text-align: right;
      margin-left: 16px;
    }
  }
}
