.blind-details__controls {
  padding: 0 24px;
  width: 100%;
  display: grid;
  box-sizing: border-box;
  margin-top: 24px;
  grid-template-columns: 0.5fr 1fr 0.5fr;
  gap: 8px;

  * {
    box-sizing: border-box;
  }

  &.disable-padding {
    padding: 0;
  }

  &--up,
  &--stop,
  &--down {
    height: 64px;
    background-color: var(--color-element);
    color: var(--color-type-primary);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: none;
    font-weight: 600;
    font-size: 14px;

    &:not(:active) {
      transition: background-color var(--button-active-transition), color var(--button-active-transition);
      background-color: var(--color-element);
      color: var(--color-type-primary);

      [stroke] {
        transition: stroke var(--button-active-transition);
        stroke: var(--color-type-primary);
      }
    }

    &:active {
      background-color: var(--color-accent);
      color: var(--color-element);

      [stroke] {
        stroke: var(--color-element);
      }
    }

    &.active {
      &:not(:active) {
        transition: background-color var(--button-active-transition), color var(--button-active-transition);
        background-color: var(--color-accent);
        color: var(--color-element);
  
        [stroke] {
          transition: stroke var(--button-active-transition);
          stroke: var(--color-element);
        }
      }
    }
  }
}