.switch-details__time-activations {
  padding: 0 24px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  .time-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
  }
}