.channel-control-box {
  display: flex;
  align-items: center;
  width: 100%;
  width: 100%;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  &__controls {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;

    &.center {
      justify-content: center;
    }
  }

  &__control {
    padding: 4px!important;

    &--border {
      border: 1px solid var(--color-type-primary);
      border-radius: 50%;
      padding: 8px;
    }

    &:not(:active) {
      path {
        transition: stroke var(--button-active-transition);
        stroke: var(--color-type-primary);
      }

      circle {
        transition: fill var(--button-active-transition), stroke var(--button-active-transition);
        stroke: var(--color-type-primary);
        fill: var(--color-element);
      }
    }

    &:active,
    &.active {
      path, rect {
        stroke: var(--color-element) !important;
      }

      .icon-on, .icon-off {
        [fill] {
          fill: var(--color-element) !important;
        }
      }

      circle {
        stroke: var(--color-accent) !important;
        fill: var(--color-accent) !important;
      }
    }
  }
}
