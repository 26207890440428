.found-driver {

  button {
    height: 36px;
    width: 36px;
  }

  .identify-btn {
    border: 1px solid var(--color-type-primary);
    border-radius: 50%;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-bulb {
      [stroke] {
        stroke: var(--color-type-primary);
      }

      [fill] {
        fill: var(--color-type-primary);
      }
    }
  }

  .register-btn {
    svg {
      height: 36px;
      width: 36px;
    }
  }
}