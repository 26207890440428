.support {
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    color: var(--hyperlink);
  }

  .add-device-icon {
    display: flex;
    justify-content: center;
    margin: 24px 0 48px 0;
  }

  .contacts {
    margin-top: 24px;

    .contact-item {
      margin-bottom: 24px;
      border: 1px dotted var(--color-type-secondary);
      border-radius: 12px;
      padding: 12px;
      margin-bottom: 12px;

      >div {
        display: flex;
        align-items: center;
        margin: 4px 0;

        .icon {
          width: 35px;
          margin-right: 12px;

          [stroke] {
            stroke: var(--color-type-primary);
          }
        }

        &:last-of-type {
          .icon {
            [fill] {
              fill: var(--color-type-primary);
            }
          }
        }
      }
    }
  }
}