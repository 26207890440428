.sensor-chart-tooltip {
  background-color: var(--color-element)!important;
  border-radius: 8px;
  padding: 12px;
  border: 1px solid var(--color-type-secondary);

  p {
    margin: 0;
  }

  .value {
    color: var(--color-accent);
  }
}