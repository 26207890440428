@import '../../../../../styles/common/badge.scss';

.device-box {
  display: flex;
  flex-direction: column;
  background-color: var(--color-element);
  border: 1px solid var(--color-element);
  border-radius: 16px;
  height: 152px;
  user-select: none;
  outline: 0;
  cursor: pointer;
  box-sizing: border-box;

  &.no-interaction {
    cursor: default;
  }

  &__head {
    height: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;
    padding: 18px 16px 0 16px;
    box-sizing: border-box;

    &.invisible {
      opacity: 0.5;
    }

    &-color {
      border: 1px solid var(--color-element-inactive);
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin-right: 8px;
    }

    &-icon {
      width: 32px;
      height: 32px;
      margin-bottom: 8px;
      flex-shrink: 0;
      position: relative;

      &--status {
        position: absolute;
        top: 0;
        right: 0;
      }

      &--no-margin {
        margin-bottom: 0;
      }

      &--no-width {
        width: auto;
      }

      svg {
        width: 100%;
        height: 100%;

        [stroke] {
          stroke: var(--color-accent);
        }
        [fill] {
          fill: var(--color-accent);
        }
      }
    }

    &-title {
      font-size: 14px;
      margin: 0;
      font-weight: 700;
      display: flex;
      flex-direction: column;
      text-align: center;

      span {
        white-space: nowrap;
        font-size: 14px;
      }

      .calibration {
        color: var(--color-accent);
        font-weight: 500;
        font-size: 12px;
      }

      .no-calibration {
        color: var(--color-type-secondary-dark);
        font-weight: 500;
        font-size: 12px;
      }
    }

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .rgb-value {
        box-sizing: border-box;
        width: 12px;
        height: 12px;
        margin: 6px 8px 6px 0;
        border-radius: 50%;
        background: var(--color-element);
        border: .5px solid var(--color-element-inactive);
      }
    }

    &-status {
      font-weight: 400;

      &-divider {
        width: 2px;
        height: 10px;
        background-color: var(--color-element-inactive);
        margin: 1px 6px 0;
      }
    }
  }

  &__name {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }

  &__draggable {
    height: 35%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-handle {
      width: 36px;
      height: 36px;
      padding: 8px 16px 8px 16px;
      cursor: grab;

      // https://docs.dndkit.com/api-documentation/draggable#touch-action
      touch-action: none;
    }
  }

  &__controls {
    width: auto;
    display: flex;
    justify-content: center;
    cursor: pointer;
    height: 30%;
    box-sizing: border-box;

    .switch,
    .gate-control,
    .device-control {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__update {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-accent);
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    min-height: 30px;
  }

  &__disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px 16px 16px;
    box-sizing: border-box;
    height: 100%;

    & > .icon-status {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }

  &__action-icon {
    display: grid;
    place-items: center;
    margin-left: auto;
  }

  &--selected {
    pointer-events: none;
    color: var(--color-element-inactive);

    .device-box__head-icon {
      svg {
        [stroke] {
          stroke: var(--color-element-inactive);
        }

        [fill] {
          fill: var(--color-element-inactive);
        }
      }
    }
  }

  &--list-item {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 16px;
    height: auto;
    box-sizing: border-box;
    min-height: 76px;

    .device-box {
      &__head {
        flex-direction: row;
        flex-shrink: initial;
        height: auto;
        padding: 16px;
        width: 100%;

        &-wrapper {
          width: auto;
          overflow: hidden;
        }

        &-icon {
          margin-bottom: 0;
          margin-right: 16px;
        }

        &-title {
          text-align: left;
        }
      }

      &__draggable,
      &__controls,
      &__disabled {
        width: auto;
        height: auto;
      }

      &__controls {
        .switch,
        .gate-control,
        .initiate-button,
        .device-control {
          padding: 0 16px;
          height: auto;
          display: flex;
          align-items: center;
        }

        .device-control {
          white-space: nowrap;
          &__value {
            font-size: 16px;
            text-transform: capitalize;
          }

          &__unit {
            font-size: 14px;
          }
        }
      }

      &__update {
        padding-right: 8px;
      }

      &__disabled {
        padding: 0 16px;
      }

      &__dragable-handle {
        padding-bottom: 0;
      }
    }
  }

  &--checkbox {
    .checkbox {
      width: auto;
      margin: 0;
      padding-right: 16px;
    }
  }

  &--turn-on {
    background-image: var(--turn-on);
  }

  &__hidden-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 100%;
    box-sizing: border-box;

    img {
      width: 28px;
      height: 21px;
      opacity: 0.2;
    }

    &-label {
      font-size: 14px;
    }
  }

  &.task-active {
    border-color: var(--color-accent);
    animation: borderAnimation 2s linear infinite;
  }

  @keyframes borderAnimation {
    0% {
      border-color: var(--color-accent);
    }
  
    50% {
      border-color: var(--color-element);
    }

    100% {
      border-color: var(--color-accent);
    }
  }
}

.device-control {
  font-weight: 600;

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__value {
    font-size: 18px;

    &--medium {
      font-size: 14px;
    }
  }

  &__icon {
    display: grid;
    margin-left: 8px;

    [class^='icon'] {
      height: 16px;
      width: 16px;
    }
  }

  &__unit {
    font-size: 12px;
    font-weight: 500;
  }
}
