.tabs {
  color: var(--color-element-inactive-dark);
  position: relative;

  &.more-btn {
    display: flex;
  }

  &__list {
    overflow-x: auto;
    white-space: nowrap;
    padding: 0 24px 24px;
    box-sizing: border-box;
    scrollbar-width: none;
    display: flex;
    align-items: center;

    &.no-padding {
      padding-left: 0;
    }

    &-item-wrapper {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }

    &-item {
      cursor: pointer;
      position: relative;
      opacity: 0.6;

      &--active {
        color: var(--color-accent);
        opacity: 1;

        .icon-heart {
          [fill] {
            fill: var(--color-accent)!important;
          }

          [stroke] {
            stroke: var(--color-accent);
          }
        }
      }

      &--indicator {
        opacity: 1;

        .indicator {
          position: absolute;
          background-color: #29D058;
          height: 10px;
          width: 10px;
          top: 3px;
          right: 3px;
          border-radius: 50%;
        }
      }

      &-content {
        display: flex;
        align-items: center;

        [class^='icon-'] {
          height: 24px;
          max-width: 30px;
          margin-right: 10px;
        }

        span {
          max-width: 250px;
          font-size: 16px;
        }
      }

      &:not(.tabs__list-item--active) svg {
        path, rect, circle, line {
          stroke: var(--color-element-inactive);
          
          &.fill {
            fill: var(--color-element-inactive);
          }
        }

        ellipse {
          fill: var(--color-element-inactive);
          stroke: var(--color-element-inactive);
        }

        &.icon-scenario {
          [fill] {
            fill: var(--color-element-inactive)!important;
          }
        }
      }

      .icon-heart {
        [fill] {
          fill: var(--color-element-inactive);
        }
      }
    }

    &.item-flex {
      .tabs__list-item-wrapper {
        flex: 1;

        .tabs__list-item-content {
          justify-content: center;
        }
      }
    }
  }

  &__more-btn {
    padding: 8px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: var(--color-element);
    width: 48px;
    height: 48px;
    margin-right: 16px;
    box-shadow: 0px 0px 3px 0px rgba(157, 160, 169, 1);
    z-index: 1;

    .icon-chevron {
      transform: rotate(-90deg);
    }

    &.open {
      .icon-chevron {
        transform: rotate(90deg);
      }
    }
  }

  &--type-default {
    .tabs {
      &__list {
        &-item-wrapper {
          margin-right: 24px;
        }

        &-item {
          border-bottom: 2px solid transparent;
          font-size: 16px;
          padding-bottom: 8px;

          &--active {
            border-bottom-color: var(--color-type-primary);
          }
        }
      }
    }
  }

  &--type-pills {
    &.bigger-tabs {
      position: absolute;
      bottom: -32px;
      left: 24px;

      .tabs__list {
        padding: 12px 24px;
      }
    }

    .tabs {
      &__list {
        &-item {
          padding: 8px 16px;
          box-sizing: border-box;
          border-radius: 8px;
          height: 48px;
          min-width: 48px;
          display: flex;
          align-items: center;
          justify-content: center;

          &--bigger {
            min-width: 90px;
            min-height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 16px;
            opacity: 0.95;
            box-shadow: 8px 8px 24px -19px rgba(66, 68, 90, 1);

            &.tabs__list-item--active {
              opacity: 1;
            }

            .text-ellipsis {
              font-weight: 700;
            }
    
            &.tabs__list-item--active {
              transform: scale(1.1);
            }
          }
        }
      }

      &__line {
        display: block;
      }
    }
  }

  &--white.tabs--type-pills {
    .tabs {
      &__list {
        &-item {
          background-color: var(--color-element);

          &--add-new {
            opacity: 1;
            border: 1px solid var(--color-accent);
            color: var(--color-accent);

            .tabs__list-item-content span {
              font-size: 28px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  &--grey.tabs--type-pills {
    .tabs {
      &__list {
        &-item {
          background-color: var(--color-background);
        }
      }
    }
  }

  &--type-compact {
    position: relative;

    .tabs__list {
      &-item-wrapper {
        margin-left: 16px;

        &:first-child {
          margin-left: 0;
        }
      }

      &-item {
        color: var(--color-type-primary);

        svg path {
          fill: var(--color-type-primary);
          stroke: var(--color-type-primary);
        }

        &--active {
          color: var(--color-accent);

          svg path {
            fill: var(--color-accent);
            stroke: var(--color-accent);
          }
        }
      }
    }

    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 183px;
      max-width: 80%;
      height: calc(100% - 24px);
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.2s;
    }

    &::before {
      left: -24px;
      background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, var(--color-background) 100%);
    }

    &::after {
      right: -24px;
      background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--color-background) 100%);
    }

    &.tabs--gradient-left::before,
    &.tabs--gradient-right::after {
      opacity: 1;
    }

    &.tabs--gradient-right.tabs--gradient-left {
      &::after,
      &::before {
        max-width: 40%;
      }
    }
  }

  .more-tabs-list {
    position: absolute;
    top: 48px;
    margin-left: -24px;
    padding: 24px;
    background-color: var(--color-background);
    width: 100%;
    z-index: 1;
    height: calc(100vh - 254px);
    overflow-y: auto;

    &.full-height {
      height: calc(100vh - 232px);
    }

    &.secondary {
      background-color: var(--color-background-secondary);
    }

    ul {
      li {
        border-radius: 8px;
        background-color: var(--color-element);
        color: var(--color-type-primary);
        height: 72px;
        margin-bottom: 16px;
        padding: 24px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;

        svg {
          [fill] {
            fill: var(--color-type-primary);
          }
  
          [stroke] {
            stroke: var(--color-type-primary);
          }
        }

        &.add-new {
          border: 2px solid var(--color-accent);
          color: var(--color-accent);
          font-size: 28px;
          font-weight: 700;
        }

        &.active {
          background-color: var(--color-accent);
          color: #fff;

          svg {
            [fill] {
              fill: #fff;
            }
    
            [stroke] {
              stroke: #fff;
            }
          }
        }

        .item-content {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;

          .icon {
            height: 32px;
            width: 32px;
            margin-right: 12px;
            display: flex;
            align-items: center;

            div {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
}
