@import '../../../../styles/mixins.scss';

.form-devices {
  &__form {
    display: none;

    &--active {
      display: block;
    }
  }

  .accordion__content {
    ul {
      .checkbox {
        margin: 8px 0;
        max-width: 700px;

        &:last-child {
          margin: 8px 0;
        }
      }
    }
  }
}

.form-actions {
  margin-top: 24px;

  .device-item__content-wrapper {
    margin-right: 48px;
  }

  .action-box--list-item {
    margin-bottom: 0;
  }

  &__placeholder-text {
    font-size: 16px;
  }
}

.edit-name__close-btn {
  padding: 0;
  margin-bottom: 30px;
}
