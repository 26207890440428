.events-list {
  .arrow_button--icon {
    height: 24px;

    svg [stroke] {
      stroke: var(--color-accent);
    }
  }
}

.events-dialog {
  padding: 0;

  .row-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .label {
      color: var(--color-type-primary);
      font-size: 14px;
      font-weight: 600;
      text-align: start;
    }

    .switch {
      width: auto;
    }
  }
}