.cover-detail-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;

  &__slider {
    display: flex;
    align-items: center;
    position: absolute;
  }

  &__tilt {
    position: absolute;
    right: 0;
    top: 46%;
    transform: translate3d(0, -50%, 0);
  }

  &--full-space {
    flex: 1;
  }

  .control-wrapper {
    position: relative;

    .popover-button {
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }
}
