.circular-progress {
  display: flex;
  align-items: center;
  justify-content: center;

  &--spinner {
    svg {
      circle {
        stroke: var(--color-accent);
      }
    }
  }
}