.action-box {
  display: flex;
  flex-direction: column;
  background: var(--color-element);
  border-radius: 16px;
  height: 152px;
  box-sizing: border-box;

  .icon-cover,
  .icon-switch {
    [stroke] {
      stroke: var(--color-accent);
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    height: 65%;

    &-control {
      width: 100%;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    cursor: pointer;

    &-action-name {
      text-align: center;
      line-height: 24px;
      font-size: 14px;
    }

    &--box {
      flex-direction: column;
      height: 65px;
      overflow: hidden;
      padding: 16px 16px 0;

      .action-icon {
        margin-right: 0;
        margin-bottom: 16px;
      }

      .action-box__head-action-name {
        margin: 0;
        white-space: nowrap;
        width: 100%;
        text-align: center;
      }

      .action-box__relative-wrapper {
        .icon-recovery,
        .icon-error-small,
        .icon-connection-error-wrapper {
          right: -12px;
        }
      }
    }
  }

  &__relative-wrapper {
    position: relative;

    .icon-recovery,
    .icon-error-small,
    .icon-connection-error-wrapper {
      right: 8px;
    }
  }

  &__wrapper {
    margin: 0 0 8px;
  }

  &__draggable {
    height: 35%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-handle {
      width: 36px;
      height: 36px;
      cursor: grab;

      // https://docs.dndkit.com/api-documentation/draggable#touch-action
      touch-action: none;
    }
  }

  &__devices {
    display: flex;
    justify-content: center;
    margin-top: 16px;

    &-item {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--color-background);
      border-radius: 6px;

      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }

  .action-control {
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--disabled {
    .action-box__content {
      color: var(--color-element-inactive);

      .action-box__content-control {
        .action-box__head {
          svg {
            [stroke] {
              stroke: var(--color-element-inactive);
            }
            [fill] {
              fill: var(--color-element-inactive);
            }
          }
        }
      }

      .trigger-event-list {
        .trigger-event-list__item-icon {
          svg {
            [stroke] {
              stroke: var(--color-element-inactive);
            }
            [fill] {
              fill: var(--color-element-inactive);
            }
          }
        }

        &__item--background {
          .trigger-event-list__item-icon {
            svg {
              [fill] {
                fill: var(--color-background);
              }
            }

            .icon-more {
              circle {
                fill: var(--color-element-inactive);
              }
            }
          }
        }
      }
    }
  }

  &--list-item {
    height: auto;
    margin-bottom: 16px;
    position: relative;

    .action-box__content {
      height: 100%;
    }

    .action-control {
      height: 100%;
      position: absolute;
      top: 0;
      align-items: center;
      display: flex;
      right: 0;

      &__advanced {
        margin-right: 16px;
      }

      &__on-off {
        height: auto;
        width: auto;
      }

      .on-off-control__icon--on {
        margin-right: 24px;
      }
    }

    .action-box {
      &__content {
        justify-content: flex-start;
        padding: 16px;
      }

      &__head {
        display: flex;
        align-items: center;
        width: calc(100% - 112px);

        &-wide {
          width: calc(100% - 40px);
        }
        &-action-name,
        &-group-name {
          text-align: left;
        }
      }

      &__wrapper {
        margin: 0 16px 0 0;
      }

      &__devices {
        display: none;
      }

      &__draggable {
        width: auto;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 16px 8px;
      }
    }

    &.action-box--advanced {
      .action-box {
        &__content {
          flex-direction: column;

          &-control {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;

            .action-box__head-action-name {
              margin: 0;
            }

            .action-control {
              position: static;

              &__advanced {
                margin-right: 0;
              }

              .switch {
                height: 32px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 374px) and (min-width: 312px) {
  .action-box:not(.action-box--list-item) {
    .action-box__devices--three-icons > div:nth-child(2) {
      display: none;
    }
  }
}
