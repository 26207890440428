.group-item {
  border-radius: 8px;
  background-color: var(--color-element);
  height: 72px;
  margin-bottom: 16px;
  padding: 24px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 24px);
  }
}
