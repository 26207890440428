.rgb-color {
  box-sizing: border-box;
  padding: 12px;
  overflow-y: auto;

  &--details {
    max-height: 380px;
    width: 100%;
  }

  &.grid {
    gap: 16px;
  }

  &__element {
    height: 88px;
    width: 88px;
    border-radius: 16px;
    display: flex;
    justify-self: center;
    cursor: pointer;

    &--active {
      box-sizing: border-box;
      border: 4px solid var(--color-element);

      &.rgb-color__element--border-dark {
        border: 4px solid var(--color-type-primary);
      }
    }

    &--border {
      box-sizing: border-box;
      border: 1px solid var(--color-type-primary);
    }
  }
}
