.installation-list-search {
  &--paper {
    border-radius: 8px!important;
    background-color: transparent!important;
  }
  
  &--content {
    padding: 8px 24px;
    min-width: 300px;
    background-color: var(--color-element);
  }
}