@import '../../../../../../../styles/mixins.scss';

.gate-history {

  .day-section {
    margin-bottom: 32px;

    .date-header {
      text-align: center;
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 12px;

      @include md() {
        text-align: left;
      }
    }
  }

  .history-error {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin-left: 12px;
      font-weight: 600;
      font-size: 16px;
    }
  }

  .load-more-container {
    display: flex;
    justify-content: center;
  }
}