@import '../../../../../../styles/mixins.scss';

.optimizer-current {
  &--internal  {
    max-width: 1000px;
    width: 100%;
  }

  .control-wrapper.control-wrapper--column {
    align-items: center;
  }

  .tabs__list {
    padding: 0;
  }

  .row-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.flex-end {
      align-items: flex-end;
    }

    .donut-chart-wrapper {
      height: auto;

      &__donut-element {
        display: flex;
        align-items: center;
        justify-content: center;
        top: unset;

        &-info {
          height: 50%;
          width: 50%;
          min-height: 70px;
          min-width: 70px;
        }

        &-bg {
          height: calc(100vw / 4);
          width: calc(100vw / 4);
          max-width: 250px;
          max-height: 250px;
        }

        &-value {
          font-size: 14px;
        }

        &-icon {
          margin-top: 0;
        }
      }
    }

    .separator {
      width: 1px;
      height: 56px;
      background-color: var(--color-element-inactive);;
    }
  }

  .view-header {
    @include rwd(365) {
      display: none;
    }
  }

  .diagram-center {
    display: flex;
    justify-content: center;
  }

  .control-wrapper .empty-state-box {
    position: relative;
    top: unset;
    left: unset;
    bottom: unset;
    right: unset;
  }
}