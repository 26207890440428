.custom-calendar {
  label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    color: var(--color-type-secondary)!important;
    position: relative;
    left: unset;
    top: unset;
    transform: none;
  }

  fieldset {
    border: 0;
  }

  .form-control {
    background: var(--color-element);
    border-radius: 8px;
    padding: 0 16px;
    border: 0;

    &::before, &::after {
      display: none;
    }

    .icon-edit {
      transform: scale(1.2);
    }
  
    input {
      padding-left: 0;
      display: flex;
      align-items: center;
      line-height: 1;
      font-size: 14px;
      color: var(--color-type-primary);
      outline: 0;
      box-shadow: none;
      width: 100%;
      box-sizing: border-box;
      height: 56px;
    }
  }

  &--covered {
    position: absolute !important;
    opacity: 0;
    width: 100%;
    left: 0;

    .form-control {
      margin-top: 0;
    }
  }

  &--hidden {
    display: none !important;
  }
}

.calendar-dialog-root {
  .MuiPickersSlideTransition-transitionContainer {
    color: #676984!important;
  }

  .MuiPickersToolbar-root {
    background-color: #676984!important;
    color: #fff;

    span {
      color: #fff;
    }
  }
  
  .Mui-selected {
    background-color: #676984!important;
    color: #fff!important;
  }
}

.actions {
  grid-column: 1 / 4;
  grid-row: 3;

  button {
    color: #676984!important;
    padding: 8px;
    font-size: 16px;
    width: 120px;

    &:last-of-type {
      width: 80px;
    }
  }
}